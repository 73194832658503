import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Pagination, Input, Button, Tabs, Popconfirm } from "antd";
import * as providers from "../../providers/approval/approval";
import {
  SysDateTransform,
  SysValidateForm,
  showToast,
} from "../../utils/global_store";
import DatePicker from "../DatePicker";
import { useLoadingContext } from "../Loading";
import ActionModal from "../ActionModal";
import { sys_colors, sys_images, sys_labels } from "../../utils/constants";
import { SearchIcon } from "../../assets";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import MyIcon from "../MyIcon";
const { TabPane } = Tabs;

const { Search } = Input;

const DataTablePaginantionFilterApproval = ({ title = "" }) => {
  const { showLoading, hideLoading } = useLoadingContext();
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let date = new Date();
  date.setDate(1);
  const [startDate, setStartDate] = useState(date);
  const [reason, set_reason] = useState("");
  const [modal, set_modal] = useState(false);
  const [modal_detail, set_modal_detail] = useState(false);
  const [data_detail, set_data_detail] = useState(null);
  const [worklist_detail, set_worklist_detail] = useState(null);
  const [selected_id, set_selected_id] = useState("");
  const [endDate, setendDate] = useState(new Date());
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const openModal = (id) => {
    set_selected_id(id);
    set_reason("");
    set_modal(true);
  };
  const approval_column = [
    { title: "Ref", dataIndex: "id", key: "id",  render:(val,record)=><Link onClick={() => loadDetail(record)}>{record.doc_reff}</Link>},
    {
      title: "Judul",
      dataIndex: "worklist_title",
      key: "worklist_title",
    },    
    { title: "Requester", dataIndex: "requester_name", key: "requester_name" },
    {
      title: "Requester NIP",
      dataIndex: "requester_id",
      key: "requester_id",
    },
    { title: "Status", dataIndex: "worklist_status", key: "worklist_status" },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sortable: true,
      render: (val, record) =>
        SysDateTransform({
          date: val,
          type: "long",
          checkIsToDay: true,
          lang: "in",
          withTime: true,
        }),
    },
    {
      title: "Action",
      dataIndex: "worklist_journey_id",
      key: "action",
      render: (val, record) => (
        <div className="btn-group" role="group">
          <Popconfirm
            title="Sure to approve?"
            onConfirm={() => handleApprove(val)}
          >
            <a>
              <CheckOutlined style={{fontSize:18,color:sys_colors.text.primary}} />
            </a>
          </Popconfirm>
          <a
            onClick={() => openModal(val)}
            style={{ marginLeft: 10 }}
          >
            <CloseOutlined style={{fontSize:18,color:sys_colors.text.primary}}/>
          </a>

        </div>
      ),
    },
  ];

  const submission_column = [
    {
      title: "Title",
      dataIndex: "worklist_title",
      key: "worklist_title",
    },
    { title: "Status", dataIndex: "worklist_status", key: "worklist_status" },
    { title: "Ref", dataIndex: "doc_reff", key: "doc_reff" },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sortable: true,
      render: (val, record) =>
        SysDateTransform({
          date: val,
          type: "long",
          checkIsToDay: true,
          lang: "in",
          withTime: true,
        }),
    },
  ];
  useEffect(() => {
    fetchData();
  }, [searchQuery, startDate, endDate]);
  const loadDetail = async (val) => {
    try {
      const resp = await providers.getDetail(val.worklist_journey_id);
      let data = JSON.parse(resp.data.data.payloads);
      let attachments = data?.details?JSON.parse(data.details):data?.payment_files?{attachments:JSON.parse(data.payment_files)}:[];
      data.details = { attachments: [] };
      data.details.attachments = [...attachments.attachments];
      console.log(resp.data.data);
      console.log("respon", data);
      set_worklist_detail(resp.data.data);
      set_data_detail(data);
      set_modal_detail(true);
    } catch (error) {
      showToast({ message: error.message });
    }
  };
  const fetchData = () => {
    let sort = `${sortField}:${sortOrder == "ascend" ? "asc" : "desc"}`;
    if (sortField == "" || sortField == null || sortField == undefined) {
      sort = "";
    }
    providers
      .getData(
        1,
        9999999999,
        searchQuery,
        SysDateTransform({ date: startDate, withTime: false, forSql: true }),
        SysDateTransform({ date: endDate, withTime: false, forSql: true }),
        sort
      )
      .then((data) => {
        let the_datas = data.data;
        setTableData(the_datas);
      })
      .catch((error) => {
        showToast({ message: error.message, type: "error" });
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };
  const handleApprove = async (id) => {
    showLoading();
    try {
      const resp = await providers.approve(id);
      showToast({ message: resp.message });
      fetchData();
    } catch (error) {
      showToast({ message: error.message });
    }
    hideLoading();
  };

  const handleReject = async () => {
    showLoading();
    try {
      SysValidateForm(["reason"], { reason });
      const resp = await providers.reject(selected_id, reason);
      showToast({ message: resp.message });
      set_modal(false);
      fetchData();
    } catch (error) {
      showToast({ message: error.message });
    }
    set_selected_id("");
    hideLoading();
  };
  return (
    <section className="section">
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>{title}</h3>
        </div>
        <div className="card-body">
          <div
            style={{
              overflow: "hidden",
              minHeight: "750px",
            }}
          >
          <div className="col-md-12">
              <div className="d-flex flex-row" style={{ alignItems: "center" }}>
                <div className="m-1">
                  <DatePicker
                    name="start_date"
                    className="btn-additional"
                    onChange={(val) => setStartDate(val)}
                    value={startDate}
                    placeholder={"Start Date"}
                  />
                </div>
                <div className="m-1">
                  <DatePicker
                    className="btn-additional"
                    name="end_date"
                  
                    onChange={(val) => setendDate(val)}
                    value={endDate}
                    placeholder={"End Date"}
                  />
                </div>
                <Input
                  placeholder="Search..."
                  allowClear
                  prefix={<SearchIcon />}
                  style={{ flex: 1, marginBottom: 10 }}
                  className="btn-height-border m-1"
                  onChange={(val) => handleSearch(val.target.value)}
                />
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Need Approval" key="1">
                <Table
                  dataSource={tableData?.need_your_approval?.data ?? []}
                  pagination={false}
                  columns={approval_column.map((col) => ({
                    ...col,
                    sorter:
                      col.key === "action"
                        ? false
                        : (a, b) => a[col["key"]].localeCompare(b[col["key"]]),
                  }))} 
                  sticky={true}
              
                  locale={{
                    emptyText: (
                      <img src={sys_images.img_empty} height={200} width={300} />
                    ),
                  }}
                  className="table-responsive"
                  style={{ marginBottom: 30,height:"550px" }}
                />
              </TabPane>
              <TabPane tab="Your Submission" key="2">
                <Table
                  dataSource={tableData?.your_submissions?.data ?? []}
                  pagination={false}
                  columns={submission_column.map((col) => ({
                    ...col,
                    sorter:
                      col.key === "action"
                        ? false
                        : (a, b) => a[col["key"]].localeCompare(b[col["key"]]),
                  }))}
                  sticky={true}
              
                  locale={{
                    emptyText: (
                      <img src={sys_images.img_empty} height={200} width={300} />
                    ),
                  }}
                  className="table-responsive"
                  style={{ marginBottom: 30,height:"550px" }}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <ActionModal
        onOk={() => handleReject()}
        onCancel={() => set_modal(false)}
        title="Reason"
        content={
          <input
            value={reason}
            name="reason"
            className="form-control"
            onChange={(event) => set_reason(event.target.value)}
          ></input>
        }
        visible={modal}
      />

      <ActionModal
        onOk={() => set_modal_detail(false)}
        onCancel={() => set_modal_detail(false)}
        title="Detail Pembayaran"
        className="form-detail"
        
        // width="500"
        content={
          <>
            <div className="row">
              <div className="col-sm-6 form-detail">
                  <div className="form-group mb-3">
                      <label className="form-label">Doc Ref :</label>
                      <p>
                      {data_detail?.overtime_no
                          ? data_detail.overtime_no
                          : data_detail?.leave_no
                          ? data_detail.leave_no
                          : data_detail?.reimbursment_no
                          ? data_detail.reimbursment_no:
                          data_detail?.id? data_detail.id
                      : ""}
                      </p>
                  </div>
                  <div className="form-group mb-3">
                      <label className="form-label">Judul  :</label>
                      <p>
                        {worklist_detail?.worklist_title?worklist_detail.worklist_title
                        : ""}
                      </p>
                      
                  </div>
                  <div className="form-group mb-5">
                      <label className="form-label">Nama Karyawan  :</label>
                      <p>
                        {worklist_detail?.requester_name?worklist_detail.requester_name
                        : ""}
                      </p>
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label">Status : </label> 
                      <p>
                          {worklist_detail?.worklist_status?(worklist_detail.worklist_status == 'S1')?'Pending':''
                            : ""}
                      </p>
                  </div>
              </div>
              <div className="col-sm-6 form-detail">
                  <div className="form-group mb-3">
                      <label className="form-label">Tanggal Dibuat :</label>
                      <p>
                          {data_detail?.created_at?data_detail.created_at:''}
                      </p>
                  </div>
                  <div className="form-group mb-3">
                      <label className="form-label">Attachment  :</label>
                      <p>
                      <img
                      src={data_detail?.details?.attachments?data_detail.details.attachments:''}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 200,
                        marginBottom: 20,
                        border:"1px solid #f5f5f5",
                      }}
                    />
                      </p>
                      
                  </div>
                  <div className="form-group mb-5">
                      <label className="form-label">Catatan  :</label>
                      <p>
                      {data_detail?.description
                          ? data_detail.description
                          : data_detail?.reason
                          ? data_detail.reason
                      : ""}
                      </p>
                  </div>
              </div>
            </div>
            


          </>
        }
        visible={modal_detail}
      />
    </section>
  );
};

export default DataTablePaginantionFilterApproval;
