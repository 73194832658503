import { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard";
import { useNavigate,useParams, Link } from "react-router-dom";
import * as providers from "../../../providers/master/role";
import convert from "../../../model/roleFormModel";
import {
    SysDateTransform,
    SysGenValueOption,
    SysJWTDecoder,
    SysValidateForm,
    showToast,
  } from "../../../utils/global_store";
import { sys_labels } from "../../../utils/constants";
import { useLoadingContext } from "../../../components/Loading";




const CreateRoleMenu = ({readOnly=false}) => {
  const navigate = useNavigate();
  const {id}= useParams();
  const [data, setData] = useState(convert.objectOfRoleFormModel({}));
  const { showLoading, hideLoading } = useLoadingContext();

  const required_field = [
    "name",
  ]

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if(id){
      handleDetail(id);
    }
  }, []);

  const handleDetail = async (id) => {
    showLoading();
    try {
      const resp = await providers.getDetail(id);
      setData(resp.data);
    } catch (error) {
      showToast({ message: error.message, type: error });
      navigate(-1);
    }
    hideLoading();
  };



  const handleSubmit  =  async () => {
   
    try{
        SysValidateForm(required_field, data);
        const resp = await providers.insertRole({
            name:data.name
        });

        showToast({ message: resp.message, type: "success" });
        navigate(-1);
    } catch(error){
        console.log(error);
        showToast({
            message: error.message,
            type: "error",
        });
    }
  }

  const handleUpdate = async () => {
    console.log(data.name)
    showLoading();
    try {
      const data_submit = {
        name: data.name,
      };
      console.log(data.id);
      SysValidateForm(required_field, data_submit);
      const resp = await providers.updateRole(data.id, data_submit);
      showToast({ message: resp.message, type: "success" });
      navigate(-1);
    } catch (error) {
      console.log(error);
      showToast({ message: error.message, type: "error" });
    }
    hideLoading();
  };

  const title = `${id ? sys_labels.action.EDIT : sys_labels.action.FORM} ${
    sys_labels.menus.ROLE
  }`;
  
  return (
    <AdminDashboard label="">
      <section className="section">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4>{title}</h4>
          </div>
          <div className="card-body">
            <div className="form-group mb-3">
                <input
                    className="form-control"
                    type="text"
                    name="name"
                    disabled={readOnly}
                    placeholder="Nama Role"
                    value={data.name}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
            <button onClick={() => (data.id ? handleUpdate() : handleSubmit())} className="btn btn-primary"> {data.id ? "Update" : "Submit"}</button>
            </div>
          </div>
        </div>
      </section>
    </AdminDashboard>
  );
};

export default CreateRoleMenu;
