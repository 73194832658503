import React from "react";

const MyIcon = ({ iconName = "dashboard", size=20 }) => {
  let Icon = require(`../assets/images/svg/dashboard.svg`);
  switch (iconName) {
    case "dashboard":
      Icon = require(`../assets/images/svg/dashboard.svg`);
      break;
    case "add":
      Icon = require(`../assets/images/svg/add.svg`);
      break;

    case "edit":
      Icon = require(`../assets/images/svg/edit.svg`);
      break;
      case "absent":
        Icon = require(`../assets/images/svg/absent.svg`);
        break;

    case "delete":
      Icon = require(`../assets/images/svg/delete.svg`);
      break;
    case "master-data":
      Icon = require(`../assets/images/svg/master.svg`);
      break;
    case "master-organisasi":
      Icon = require(`../assets/images/svg/organisasi.svg`);
      break;
    case "master-payroll":
      Icon = require(`../assets/images/svg/masterpayroll.svg`);
      break;
    case "payroll":
      Icon = require(`../assets/images/svg/payroll.svg`);
      break;
    case "transaksi":
      Icon = require(`../assets/images/svg/transaksi.svg`);
      break;
    case "report":
      Icon = require(`../assets/images/svg/report.svg`);
      break;
    case "setting":
      Icon = require(`../assets/images/svg/setting.svg`);
      break;
    default:
      Icon = require(`../assets/images/svg/dashboard.svg`);
      break;
  }
  return <Icon.ReactComponent width={size} height={size} />;
};

export default MyIcon;
