import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as branch_providers from "../../../providers/master/branch";
import DataTablePagination from "../../../components/DataTable";
import ActionModal from "../../../components/ActionModal";
import { SysDateTransform, showToast } from "../../../utils/global_store";
import { useNavigate } from "react-router-dom";
import { sys_labels } from "../../../utils/constants";
import { routes_name } from "../../../route/static_route";
import { PlusOutlined, FileOutlined } from "@ant-design/icons";
import MyIcon from "../../../components/MyIcon";
const Branch = () => {
  const navigate = useNavigate();
  const [message, set_message] = useState("");
  const [id_branch, set_branch] = useState("");
  const [modal, set_modal] = useState(false);

  const stringSlide = (string) => {
    return string.slice(0, 100);
  };

  const columns = [
    {
      title: "Cabang",
      dataIndex: "name",
      key: "name",
      sortable: true,
      width: "11%",
    },
    // {
    //   title: "Perusahaan",
    //   dataIndex: "company",
    //   sortable: true,
    //   key: "company",
    //   width: "11%",
    //   render: (val) => val.company_name,
    // },
    {
      title: "Alamat",
      dataIndex: "address",
      key: "address",
      sortable: true,
      width: "20%",
      render: (val) => `${val.slice(0, 350)}...`,
    },
    {
      title: "Radius (Km)",
      dataIndex: "radius",
      key: "radius",
      width: "6%",
      sortable: true,
    },
    {
      title: "Telp Utama",
      dataIndex: "primary_phone",
      key: "primary_phone",
      width: "10%",
      sortable: true,
    },
    {
      title: "Telp Lain",
      dataIndex: "secondary_phone",
      key: "secondary_phone",
      width: "10%",
      sortable: true,
    },
    // { title: "Created Date", dataIndex: "created_at", key: "created_at", sortable: true,render:(val,record)=>SysDateTransform({date:val,type:'long',checkIsToDay:true,lang:'in',withTime:true}) },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      width: "7%",
      render: (val, record) => (
        <div className="btn-group" role="group">
          <a
            onClick={() => navigate(`/master-data/branch/show/${val}`)}
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="add" />
          </a>

          <a
            onClick={() => navigate(`/master-data/branch/detail/${val}`)}
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="edit" />
          </a>
          <a onClick={() => openModal(record)}>
            <MyIcon iconName="delete" />
          </a>
        </div>
      ),
    },
  ];
  const action = [
    <Link
      to="/master-data/branch/create"
      className="btn icon icon-left btn-outline-primary"
    >
      <PlusOutlined /> {sys_labels.action.ADD}
    </Link>,
  ];
  const handleDelete = async () => {
    set_modal(false);
    try {
      const resp = await branch_providers.deleteData(id_branch);
      showToast({ message: resp.message, type: "info" });
      window.location.reload();
    } catch (error) {
      showToast({ message: error.message, type: "error" });
    }
  };
  const openModal = async (val) => {
    set_message(val.name);
    set_branch(val.id);
    set_modal(true);
  };
  return (
    <AdminDashboard label="">
      <DataTablePagination
        fetchDataFunc={branch_providers.getData}
        columns={columns}
        title={`${sys_labels.menus.BRANCH}`}
        action={action}
      />
      <ActionModal
        onOk={handleDelete}
        onCancel={() => set_modal(false)}
        title="Confirmation"
        content={`Are you sure to delete ${message}?`}
        visible={modal}
      />
    </AdminDashboard>
  );
};

export default Branch;
