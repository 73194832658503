import React from "react";
import numeral from "numeral";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export default class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      options: { scales: { gridLines: { display: false } } },
      // options: {
      //   scales: {
      //     xAxes: [
      //       {
      //         gridLines: {
      //           display: false,
      //         },
      //       },
      //     ],
      //     yAxes: [
      //       {
      //         ticks: {
      //           beginAtZero: true,
      //           callback: function (value) {
      //             return numeral(value).format("0.0"); // memformat nilai angka dengan pemisah ribuan
      //           },
      //         },
      //       },
      //     ],
      //   },
      // },
    };
  }

  render() {
    return (
      <div id="chart">
        <Line
          min
          options={{
            
            plugins:{

              legend:{
                display:false,
                labels:{
                  color:"#fff"
                }
              }
            },
            elements: {
              point: {
                radius: 0,
              },
              line: { borderWidth: 5,borderColor:"#fff" },
            },
            scales: {
              
              x: {
                ticks:{
                  color:"#fff"
                },
                grid: {
                  display: false,
                },
              },
              y: {
                ticks:{
                  color:"#fff"
                },
                min: 0,
                grid: {
                  color: "#fff",
                },
              },
            },
          }}
          data={this.state.data}
          height={200}
        />
      </div>
    );
  }
}
