// HOW TO IMPORT ?
// const Convert = require('location/leaveFormModel.js'); 
// OR
// import Convert from 'location/leaveFormModel.js'
// HOW TO USE?
// FOR OBJECT
// const data = Convert.objectOfleaveFormModel(data)
// FOR ARRAY
// const data = Convert.listOfleaveFormModel(data)
const modelOfDataRoleFormModel = {
	name: '',

};
function listOfRoleFormModel(data = []) {
  var listData = [modelOfDataRoleFormModel];
  listData = [];
  try {
    data.map((val) => {
      var object = {
				name: val.name ?? null,
				
      };
      listData.push(object);
    });
  } catch (error) {
    console.log(error.message);
  }
  return listData;
}
function objectOfRoleFormModel(data = null) {
  var objectData = modelOfDataRoleFormModel;
  if (data == null) {
    return null;
  }
  try {
		objectData.name = data.name ?? null;
  } catch (error) {
    console.log(error.message);
  }
  return objectData;
}
module.exports = {
  listOfRoleFormModel: listOfRoleFormModel,
  objectOfRoleFormModel: objectOfRoleFormModel,
};




  