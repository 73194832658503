import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as providers from "../../../providers/master/schedule";
import DataTablePagination from "../../../components/DataTable";
import ActionModal from "../../../components/ActionModal";
import { SysDateTransform, showToast } from "../../../utils/global_store";
import { useNavigate } from "react-router-dom";
import { sys_labels } from "../../../utils/constants";
import { routes_name } from "../../../route/static_route";
import MyIcon from "../../../components/MyIcon";
import { PlusOutlined } from "@ant-design/icons";

const Schedule = () => {
  const navigate = useNavigate();
  const [message, set_message] = useState("");
  const [id, set_id] = useState("");
  const [modal, set_modal] = useState(false);
  const columns = [
    {
      title: "Nama",
      dataIndex: "title",
      key: "title",
      sortable: true,
      width: "20%",
    },
    {
      title: "Tanggal Mulai",
      dataIndex: "start_date",
      sortable: true,
      key: "start_date",
      width: "18%",
      render: (val, record) =>
        SysDateTransform({
          date: val,
          type: "long",
          checkIsToDay: true,
          lang: "in",
        }),
    },
    {
      title: "Tanggal Selesai",
      dataIndex: "end_date",
      sortable: true,
      key: "end_date",
      width: "18%",
      render: (val, record) =>
        SysDateTransform({
          date: val,
          type: "long",
          checkIsToDay: true,
          lang: "in",
        }),
    },
    {
      title: "Jam Masuk",
      dataIndex: "time_in",
      sortable: true,
      key: "time_in",
      width: "15%",
    },
    {
      title: "Jam Keluar",
      dataIndex: "time_out",
      sortable: true,
      key: "time_out",
      width: "15%",
    },

    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (val, record) => (
        <div className="btn-group" role="group">
          <a
            onClick={() => navigate(`${routes_name.M_SCHEDULE_SHOW}${val}`)}
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="add" />
          </a>

          <a
            onClick={() => navigate(`${routes_name.M_SCHEDULE_DETAIL}${val}`)}
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="edit" />
          </a>
          <a onClick={() => openModal(record)}>
            <MyIcon iconName="delete" />
          </a>
        </div>
      ),
    },
  ];
  const action = [
    <Link
      to={routes_name.M_SCHEDULE_CREATE}
      className="btn icon icon-left btn-outline-primary"
    >
      <PlusOutlined /> {sys_labels.action.ADD}
    </Link>,
  ];
  const handleDelete = async () => {
    set_modal(false);
    try {
      const resp = await providers.deleteData(id);
      showToast({ message: resp.message, type: "info" });
      window.location.reload();
    } catch (error) {
      showToast({ message: error.message, type: "error" });
    }
  };
  const openModal = async (val) => {
    set_message(val.description);
    set_id(val.id);
    set_modal(true);
  };

  return (
    <AdminDashboard label={sys_labels.menus.MASTER}>
      <DataTablePagination
        fetchDataFunc={providers.getData}
        columns={columns}
        title={sys_labels.menus.SCHEDULE}
        action={action}
      />
      <ActionModal
        onOk={handleDelete}
        onCancel={() => set_modal(false)}
        title="Confirmation"
        content={`Are you sure to delete ${message}?`}
        visible={modal}
      />
    </AdminDashboard>
  );
};

export default Schedule;
