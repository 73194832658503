import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import AdminDashboard from "../../AdminDashboard";
import * as leave_mass_providers from "../../../providers/master/leave_mass";
import DataTablePagination from "../../../components/DataTable";
import ActionModal from "../../../components/ActionModal";
import { SysDateTransform, showToast } from "../../../utils/global_store";
import { useNavigate } from "react-router-dom";
import { routes_name } from "../../../route/static_route";
import { sys_labels } from "../../../utils/constants";
import { PlusOutlined } from "@ant-design/icons";
import MyIcon from "../../../components/MyIcon";

const LeaveMass = () => {
  const navigate = useNavigate();
  const [message, set_message] = useState("");
  const [id, set_id] = useState("");
  const [modal, set_modal] = useState(false);
  const columns = [
    {
      title: "Keterangan",
      dataIndex: "leave_name",
      key: "leave_name",
      sortable: true,
      width: "20%",
    },
    {
      title: "Tanggal Cuti",
      dataIndex: "leave_date",
      key: "leave_date",
      sortable: true,
      width: "15%",
      render: (val, record) =>
        SysDateTransform({
          date: val,
          type: "long",
          checkIsToDay: true,
          lang: "in",
          withTime: false,
        }),
    },
    // { title: "Created Date", dataIndex: "created_at", key: "created_at" },
    // SysDateTransform
    {
      title: "Tanggal Buat",
      dataIndex: "created_at",
      sortable: true,
      key: "created_at",
      width: "15%",
      render: (val, record) =>
        SysDateTransform({
          date: val,
          type: "long",
          checkIsToDay: true,
          lang: "in",
          withTime: true,
        }),
    },

    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (val, record) => (
        <div className="btn-group" role="group">
          <a
            onClick={() => navigate(`${routes_name.M_LEAVE_MASS_SHOW}${val}`)}
            style={{ marginRight: 10 }}
          >
            <MyIcon iconName="add" />
          </a>
          <a
            onClick={() => navigate(`${routes_name.M_LEAVE_MASS_DETAIL}${val}`)}
            style={{ marginRight: 10 }}
          >
            <MyIcon iconName="edit" />
          </a>
          <a onClick={() => openModal(record)}>
            <MyIcon iconName="delete" />
          </a>
        </div>
      ),
    },
  ];
  const action = [
    <Link
      to={routes_name.M_LEAVE_MASS_CREATE}
      className="btn icon icon-left btn-outline-primary"
    >
      <PlusOutlined /> {sys_labels.action.ADD}
    </Link>,
  ];
  const handleDelete = async () => {
    set_modal(false);
    try {
      const resp = await leave_mass_providers.deleteData(id);
      showToast({ message: resp.message, type: "info" });
      window.location.reload();
    } catch (error) {
      showToast({ message: error.message, type: "error" });
    }
  };
  const openModal = async (val) => {
    set_message(val.leave_name);
    set_id(val.id);
    set_modal(true);
  };

  return (
    <AdminDashboard label={sys_labels.menus.MASTER}>
      <DataTablePagination
        fetchDataFunc={leave_mass_providers.getData}
        columns={columns}
        title={sys_labels.menus.LEAVE_MASS}
        action={action}
      />
      <ActionModal
        onOk={handleDelete}
        onCancel={() => set_modal(false)}
        title="Confirmation"
        content={`Are you sure to delete ${message}?`}
        visible={modal}
      />
    </AdminDashboard>
  );
};

export default LeaveMass;
