import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import AdminDashboard from "../../AdminDashboard";
import convert from "../../../model/leaveFormModel";
import * as providers from "../../../providers/transaction/leave";
import {
  SysDateTransform,
  SysJWTDecoder,
  showToast,
} from "../../../utils/global_store";
import { sys_labels } from "../../../utils/constants";
import { useLoadingContext } from "../../../components/Loading";
const LeaveShow = ({ readOnly = false }) => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoadingContext();
  const { id } = useParams();
  const [data, setData] = useState(convert.objectOfleaveFormModel({}));
  const [detail_leave, setDetailLeave] = useState({});
  const token = SysJWTDecoder();
  
  const title = `${id ? sys_labels.action.DETAIL : sys_labels.action.FORM} ${
    sys_labels.menus.LEAVE 
  }`;
  

  useEffect(() => {
    if (token.role != "admin") {
      setData((prev) => ({ ...prev, employee_id: token.employee_id }));
    }
   
    if (id) {
      getLeaveDetail(id);
      console.log(detail_leave)
    }
  }, []);

  const getLeaveDetail = async(id) => {
      // showLoading();
      try {
        const resp = await providers.getLeaveDetail(id);
        setDetailLeave(resp.data);
      } catch (error) {
        showToast({ message: error.message, type: error });
        navigate(-1);
      }

      // hideLoading();
  }

  return (
    <AdminDashboard label="">
      <section className="section">
        <div className="row">
          <div className="col-sm-6">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3>{title}</h3>
              </div>
              <div className="card-body">
                <div className="form form-horizontal">
                  <div className="form-body">
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <table className="table table-striped table-hover table-detail">
                          <tbody>
                            <tr>
                              <th width="200" className="p-3">Nomor Cuti</th>
                              <td width={20}></td>
                              <td className="p-3">{detail_leave.leave_no}</td>
                            </tr>
                            <tr>
                              <th width="200" className="p-3">Tipe Cuti</th>
                              <td width={20} className="p-3"></td>
                              <td className="p-3">
                                {detail_leave?.leave_type?.name?detail_leave.leave_type.name:''}
                              </td>
                            </tr>
                            <tr>
                              <th className="p-3">Tanggal Mulai Cuti</th>
                              <td width={20} className="p-3"></td>
                              <td className="p-3">
                                {SysDateTransform({date:detail_leave.leave_date,checkIsToDay:true,withTime:false,type:'long',lang:'in'})}
                              </td>
                            </tr>
                            <tr>
                              <th width="200" className="p-3">Lama Cuti (Hari)</th>
                              <td width={20} className="p-3"></td>
                              <td className="p-3">{detail_leave.amount}</td>
                            </tr>
                            <tr>
                              <th width="200" className="p-3">Alasan</th>
                              <td width={20} className="p-3"></td>
                              <td className="p-3">{detail_leave.reason}</td>
                            </tr>
                            <tr>
                              <th width="200" className="p-3">Status</th>
                              <td width={20} className="p-3"></td>
                              <td className="p-3">{detail_leave.is_approved }</td>
                            </tr>
                            <tr>
                              <th width="200" className="p-3">Attachment</th>
                              <td width={20} className="p-3"></td>
                              <td className="p-3">
                                <img src={detail_leave?.details?.attachments?detail_leave.details.attachments:''} width="100%"></img>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3>Data Karyawan</h3>
              </div>
              <div className="card-body">
                <div className="form form-horizontal">
                  <div className="form-body">
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <table className="table table-striped table-hover table-detail">
                          <tbody>
                            <tr>
                              <th width="200" className="p-3">Nama</th>
                              <td width={20}></td>
                              <td className="p-3">
                                {detail_leave?.employee?.full_name?detail_leave.employee.full_name:''}
                              </td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-ms-12">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h3>Persetujuan Terakhir</h3>
                </div>
                <div className="card-body">
                  <div className="form form-horizontal">
                    <div className="form-body">
                      <div className="row mt-3">
                        <div className="col-sm-12">
                          <table className="table table-striped table-hover table-detail">
                            <tbody>
                              <tr>
                                <th width="200" className="p-3">Posisi</th>
                                <td width={20}></td>
                                <td className="p-3">
                                  {detail_leave?.approval_status?.position?detail_leave.approval_status.position:'-'}
                                </td>
                              </tr>
                              <tr>
                                <th width="200" className="p-3">Level</th>
                                <td width={20}></td>
                                <td className="p-3">
                                {detail_leave?.approval_status?.level?detail_leave.approval_status.level:'-'}
                                </td>
                              </tr>
                              <tr>
                                <th width="200" className="p-3">Admin</th>
                                <td width={20}></td>
                                <td className="p-3">
                                {detail_leave?.approval_status?.in_admin?detail_leave.approval_status.in_admin:'-'}
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </AdminDashboard>
  );
};

export default LeaveShow;
