import Router from "./route";
import Footer from "./components/Footer";
import { LoadingProvider } from "./components/Loading";
import "./index.css";

function App() {
  // console.log = function(){};
  // console.debug = function(){};
  // console.error = function(){};
  // console.warn = function(){};
  // console.info = function(){};
  
  // document.body.style.zoom = '75%'
  return (
    <div className="App">
      <LoadingProvider>
        <Router />
      </LoadingProvider>
    </div>
  );
}
export default App;
