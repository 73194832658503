import { Link, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as providers from "../../../providers/report/global";
import DataTablePagination, {
  DataTablePaginationReportDetail,
} from "../../../components/DataTable";
import { sys_colors, sys_labels } from "../../../utils/constants";
import {
  SysCurrencyTransform,
  SysDateTransform,
  SysJWTDecoder,
  SystoCamelCase,
  showToast,
} from "../../../utils/global_store";
import TaxPdf from "../../PDF/tax_pdf";
import { pdf } from "@react-pdf/renderer";
import { PrinterOutlined } from "@ant-design/icons";

const ReportTaxDetail = () => {
  const location = useLocation();
  const data = location.state;
  const user = SysJWTDecoder();
  let tunjangan = 0;
  tunjangan += data?.payroll_fix_allowances?.total??0;
  tunjangan += data?.payroll_other_allowances?.total??0;
  // tunjangan += data?.payroll_incentives?.total??0;
  tunjangan += data?.payroll_overtimes??0;
  let jaminan =0;
  jaminan += data?.payroll_insurances?.jp??0;
  jaminan += data?.payroll_insurances?.jht??0;
  jaminan += data?.payroll_insurances?.kesehatan??0;
  jaminan += data?.payroll_insurances?.jkm??0;
  let potongan = jaminan;
  potongan += data?.payroll_fix_deductions?.total??0;
  potongan += data?.payroll_not_fix_deductions?.total??0;
  const date = new Date()
  const handlePrintPDF = async () => {
    try {
      let tax_data = data;      
      const pdfData = TaxPdf(tax_data, date.getMonth(), date.getFullYear(),null); // Generate the PDF data
      const pdfBlob = await pdf(pdfData).toBlob(); // Convert to a PDF Blob

      const fileURL = URL.createObjectURL(pdfBlob);
      window.open(fileURL, "_blank");
    } catch (error) {
      console.log(error);
      showToast({ message: error.message });
    }
  };
  return (
    <AdminDashboard label={sys_labels.menus.REPORT}>
      <section className="section">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{sys_labels.menus.TAX}</h3>
            <PrinterOutlined style={{fontSize:"50px",color:sys_colors.text.primary}} onClick={()=>handlePrintPDF()} />
          </div>
          <div className="card-body">
            <div className="form form-horizontal">
              <div className="form-body">
                <h5 className="mt-3">Identitas Pemotong</h5>
                <div className="row p-3">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Nama</label>
                      <p className="fw-bold">{user?.full_name?.toUpperCase() ?? ""}</p>
                    </div>
                  </div>
                </div>

                <h5 className="mt-3">
                  Identitas Penerima Penghasilan yang dipotong
                </h5>
                <div className="row p-3">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>NPWP</label>
                      <p className="fw-bold">{data?.npwp ?? "-"}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Alamat</label>
                      <p className="fw-bold">{data?.residential_address ?? "-"}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>status</label>
                      <p className="fw-bold">{data?.ptkp ?? "-"}</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Nama</label>
                      <p className="fw-bold">
                        {data?.full_name?.toUpperCase() ?? "-"}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Jenis Kelamin</label>
                      <p className="fw-bold">{data?.gender?.toUpperCase() ?? "-"}</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Jabatan</label>
                      <p className="fw-bold">
                        {data?.job_position_name?.toUpperCase() ?? "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <h5 className="mt-3">
                  Rincian Penghasilan dan Perhitungan PPh Pasal 21
                </h5>
                <h5 className="p-3">
                  Penghasilan Bruto
                </h5>
                <div className="row px-3">
                  <div className="col-md-6 form-group">
                    <label>1. Gaji Pokok</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_base_salary??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>2. Tunjangan PPh</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_tax_paid_by_company??0,currency:""})}</p>
                  </div> 
                  <div className="col-md-6 form-group">
                    <label>3. Tunjangan Lainnya, Uang Lembur dan Sejenisnya</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:tunjangan??0,currency:""})}</p>
                  </div> 
                  <div className="col-md-6 form-group">
                    <label>4. Honorarium dan Imbalan Lain Sejenisnya</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_incentives??0,currency:""})}</p>
                  </div> 
                  <div className="col-md-6 form-group">
                    <label>5. Premi Asuransi yang Dibayarkan Pemberi Kerja</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_insurances?.other_insurances_by_company??0,currency:""})}</p>
                  </div> 
                  <div className="col-md-6 form-group">
                    <label>6. Jumlah Penghasilan Bruto</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_final_salary??0,currency:""})}</p>
                  </div>
                </div>
                <h5 className="p-3">
                  Pengurangan
                </h5>
                <div className="row px-3">
                  <div className="col-md-6 form-group">
                    <label>7. Potongan {data?.payroll_fix_deductions?.details?.map(((val,index)=>{return val.name+(index==data.payroll_fix_deductions.details.length -1?"":",")}))}</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_fix_deductions?.total??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>8. Potongan Lainnya</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_not_fix_deductions?.total??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>9. Asuransi</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_insurances?.other_insurances??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>10. Jaminan Kematian, Pensiun dan Sejenisnya</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:jaminan,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>11. Jumlah Potongan</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:potongan,currency:""})}</p>
                  </div>
                </div>
                <h5 className="p-3">
                  Pengurangan PPh Pasal 21
                </h5>
                <div className="row px-3">
                  <div className="col-md-6 form-group">
                    <label>12. Penghasilan Netto</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_base_salary??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>13. Penghasilan Tidak Kena Pajak</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_pkp??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>14. Penghasilan Kena Pajak</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_pkp??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>15. PPh Pasal 21 Terhutang</label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_terhutang??0,currency:""})}</p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>16. PPh Pasal 21 yang Telah Dilunasi </label>
                  </div>
                  <div className="col-md-6 align-items-end">
                    <p className="text-right text-end fw-bold">{SysCurrencyTransform({num:data?.payroll_dibayar??0,currency:""})}</p>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminDashboard>
  );
};

export default ReportTaxDetail;
