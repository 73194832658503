import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import convert from "../../model/payrollModel";
import {
  SysCurrencyTransform,
  SysJWTDecoder,
  SysMonthTransform,
} from "../../utils/global_store";
const borderColor = "#ECA5CB";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 30,
  },
  content: {
    paddingBottom: 10,
  },
  container: {
    width: "100%",
    height: "100%",
    padding: 10,
  },
  containerLabel: {
    fontSize: 12,
    marginTop: 20,
    marginBottom: 5,
    fontWeight: "bold",
    width: "100%",
    borderBottomWidth: 0.5,
    // borderBottomColor: borderColor,
    // color: borderColor,
    borderStyle: "solid",
  },

  containerContent: {
    fontSize: 8,
    padding: 5,
    fontWeight: "bold",
    width: "33%",
    textAlign: "left",
    flexDirection: "column",
  },

  containerContentSummary: {
    fontSize: 8,
    padding: 8,
    fontWeight: "bold",
    width: "50%",
    textAlign: "left",
    flexDirection: "column",
  },
  containerContentSummaryRight: {
    fontSize: 8,
    padding: 8,
    fontWeight: "bold",
    width: "50%",
    textAlign: "right",
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
  },

  row_between: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "wrap",
  },
  textHeader: {
    fontSize: 12,
    fontWeight: "bold",
    paddingVertical: 5,
    width: "50%",
  },
  textEmployee: {
    fontSize: 8,
    fontWeight: "bold",
  },
  border: {
    width: "100%",
    borderBottomWidth: 1,
    paddingVertical: 5,
    borderBottomColor: borderColor,
    borderStyle: "solid",
  },
  right_align: {
    textAlign: "right",
    marginRight: 10,
  },

  center_align: {
    textAlign: "center",
  },
});

const TaxPdf = (data, month, year, token = null) => {
  // console.log(month,year);
  function toCamelCase(str) {
    if (str == "value_to_add") return "Penerimaan";
    if (str == "value_to_reduce") return "Potongan";
    if (str == "late_penalty") return "Terlambat";
    if (str == "jht_by_employee") return "JHT oleh Pegawai";
    if (str == "jht_by_company") return "JHT oleh Perusahaan";
    if (str == "kesehatan_by_employee") return "Kesehatan oleh Pegawai";
    if (str == "kesehatan_by_company") return "Kesehatan oleh Perusahaan";
    if (str == "jp_by_company") return "JP oleh Perusahaan";
    if (str == "jkm_by_company") return "JKM oleh Perusahaan";
    if (str == "jkk_by_company") return "JKK oleh Perusahaan";
    if (str == "jp_by_employee") return "JP oleh Pegawai";
    if (str == "other_insurance_by_employee")
      return "Asuransi Lain oleh Pegawai";
    if (str == "other_insurance_by_company")
      return "Asuransi Lain oleh Perusahaan";
    if (str == "fix_deduction") return "Potongan Tetap";
    if (str == "not_fix_deduction") return "Potongan Tidak Tetap";
    if (str == "pajak") return "PPh21";
    if (str == "tunjangan_pajak") return "Tunjangan PPh21";
    return str
      .replace(/_([a-z])/g, (match, char) => " " + char.toUpperCase())
      .replace(/^./, (firstChar) => firstChar.toUpperCase());
  }

  const user = SysJWTDecoder(token ?? null);
  const date = new Date();
  let tunjangan = 0;
  tunjangan += data?.payroll_fix_allowances?.total??0;
  tunjangan += data?.payroll_other_allowances?.total??0;
  // tunjangan += data?.payroll_incentives?.total??0;
  tunjangan += data?.payroll_overtimes??0;
  let jaminan =0;
  jaminan += data?.payroll_insurances?.jp??0;
  jaminan += data?.payroll_insurances?.jht??0;
  jaminan += data?.payroll_insurances?.kesehatan??0;
  jaminan += data?.payroll_insurances?.jkm??0;
  let potongan = jaminan;
  potongan += data?.payroll_fix_deductions?.total??0;
  potongan += data?.payroll_not_fix_deductions?.total??0;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <Text style={[styles.textHeader]}>{user.company}</Text>

          <View style={[styles.border, { flexDirection: "row-reverse" }]}>
            <Text style={styles.textEmployee}>
              {data.employee_id} - {data.full_name} -{" "}
              {SysMonthTransform(month, "long", "in")} {year}
            </Text>
          </View>
          <View style={styles.container}>
            <View style={styles.containerLabel}>
              <Text>Indentitas Pemotong</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.containerContent}>
                <Text>Nama</Text>
                <Text>{user?.full_name?.toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.containerLabel}>
              <Text>Indentitas Penerima Penghasilan yang dipotong</Text>
            </View>
            <View style={styles.row}>
              <View style={styles.containerContent}>
                <Text>NPWP</Text>
                <Text>{data.npwp}</Text>
              </View>
              <View style={styles.containerContent}>
                <Text>Alamat</Text>
                <Text>{data.residential_address}</Text>
              </View>

              <View style={styles.containerContent}>
                <Text>Status</Text>
                <Text>{data.ptkp}</Text>
              </View>

              <View style={styles.containerContent}>
                <Text>Nama</Text>
                <Text>{data.full_name?.toUpperCase() ?? ""}</Text>
              </View>

              <View style={styles.containerContent}>
                <Text>Jenis Kelamin</Text>
                <Text>{data.gender?.toUpperCase() ?? ""}</Text>
              </View>

              <View style={styles.containerContent}>
                <Text>Jabatan</Text>
                <Text>{data.job_position_name?.toUpperCase() ?? ""}</Text>
              </View>
            </View>
            <View style={styles.containerLabel}>
              <Text>Rincian Penghasilan dan Perhitungan PPh Pasal 21</Text>
            </View>
            <View style={styles.containerContent}>
              <Text>Penghasilan Bruto</Text>
            </View>

            <View style={styles.row_between}>
              <View style={styles.containerContentSummary}>
                <Text>1. Gaji Pokok</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_base_salary??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>2. Tunjangan PPh</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_tax_paid_by_company??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>3. Tunjangan Lainnya, Uang Lembur dan Sejenisnya</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:tunjangan??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>4. Honorarium dan Imbalan Lain Sejenisnya</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_incentives??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>5. Premi Asuransi yang Dibayarkan Pemberi Kerja</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_insurances?.other_insurances_by_company??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>6. Jumlah Penghasilan Bruto</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_final_salary??0,currency:""})}</Text>
              </View>
            </View>
            <View style={styles.containerContent}>
              <Text>Pengurangan</Text>
            </View>
            <View style={styles.row_between}>
              <View style={styles.containerContentSummary}>
                <Text>7. Potongan {data?.payroll_fix_deductions?.details?.map(((val,index)=>{return val.name+(index==data.payroll_fix_deductions.details.length -1?"":",")}))}</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_fix_deductions?.total??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>8. Potongan Lainnya</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_not_fix_deductions?.total??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>9. Asuransi</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_insurances?.other_insurances??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>10. Jaminan Pensiun, Kematian dan Sejenisnya</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:jaminan,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>11. Jumlah Potongan</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:potongan,currency:""})}</Text>
              </View>
            </View>
            <View style={styles.containerContent}>
              <Text>Pengurangan PPh Pasal 21</Text>
            </View>
            <View style={styles.row_between}>
              <View style={styles.containerContentSummary}>
                <Text>12. Penghasilan Netto</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_base_salary??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>13. Penghasilan Tidak Kena Pajak</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_pkp??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>14. Penghasilan Kena Pajak</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_pkp??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>15. PPh Pasal 21 Terhutang</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_terhutang??0,currency:""})}</Text>
              </View>
              <View style={styles.containerContentSummary}>
                <Text>16. PPh Pasal 21 yang Telah Dilunasi</Text>
              </View>
              <View style={styles.containerContentSummaryRight}>
                <Text>{SysCurrencyTransform({num:data?.payroll_dibayar??0,currency:""})}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TaxPdf;
