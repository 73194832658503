import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as employee_providers from "../../../providers/master/employee";
import DataTablePagination from "../../../components/DataTable";
import ActionModal from "../../../components/ActionModal";
import { showToast } from "../../../utils/global_store";
import { useNavigate } from "react-router-dom";
import { routes_name } from "../../../route/static_route";
import { sys_labels } from "../../../utils/constants";
import MyIcon from "../../../components/MyIcon";
import { PlusOutlined } from "@ant-design/icons";

const Employee = () => {
  const navigate = useNavigate();
  const [message, set_message] = useState("");
  const [id_branch, set_branch] = useState("");
  const [modal, set_modal] = useState(false);
  const columns = [
    {
      title: "Pegawai",
      dataIndex: "full_name",
      key: "full_name",
      sortable: true,
    },

    {
      title: "Cabang",
      dataIndex: "branch",
      key: "branch",
      render: (val) => val.name,
    },

    {
      title: "Perusahaan",
      dataIndex: "branch",
      key: "company",
      render: (val) => val.company.name,
    },
    { title: "Divisi", dataIndex: "division", key: "division", sortable: true },
    { title: "Posisi", dataIndex: "position", key: "position", sortable: true },
    {
      title: "Jabatan",
      dataIndex: "job_level",
      key: "job_level",
      sortable: true,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      width: "11%",
      render: (val, record) => (
        <div className="btn-group" role="group">
          <a
            onClick={() =>
              navigate(`${routes_name.M_SALARY_COMPONENT_SHOW}${val}`)
            }
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="add" />
          </a>

          <a
            onClick={() =>
              navigate(`${routes_name.M_SALARY_COMPONENT_DETAIL}${val}`)
            }
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="edit" />
          </a>
        </div>
      ),
    },
  ];
  const action = [
    <Link
      to={routes_name.M_SALARY_COMPONENT_MULTIPLE}
      className="btn icon icon-left btn-outline-primary"
    >
      <PlusOutlined /> Tambah
    </Link>,
  ];
  return (
    <AdminDashboard label={sys_labels.menus.MASTER_PAYROLL}>
      <DataTablePagination
        fetchDataFunc={employee_providers.getData}
        columns={columns}
        title={sys_labels.menus.SALARY_COMPONENT}
        action={action}
      />
    </AdminDashboard>
  );
};

export default Employee;
