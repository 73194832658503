import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as employee_providers from "../../../providers/master/employee";
import DataTablePagination from "../../../components/DataTable";
import ActionModal from "../../../components/ActionModal";
import { showToast } from "../../../utils/global_store";
import { useNavigate } from "react-router-dom";
import { routes_name } from "../../../route/static_route";
import { sys_labels } from "../../../utils/constants";
import { PlusOutlined } from "@ant-design/icons";
import MyIcon from "../../../components/MyIcon";

const Employee = () => {
  const navigate = useNavigate();
  const [message, set_message] = useState("");
  const [id_branch, set_branch] = useState("");
  const [modal, set_modal] = useState(false);
  const columns = [
    {
      title: "Nama",
      dataIndex: "full_name",
      key: "full_name",
      sortable: true,
      width: "20%",
    },

    {
      title: "Cabang",
      dataIndex: "branch",
      key: "branch",
      sortable: true,
      width: "11%",
      render: (val) => val.name,
    },

    // {
    //   title: "Perusahaan",
    //   dataIndex: "branch",
    //   key: "company",
    //   render: (val) => val.company.name,
    // },
    { title: "Divisi", dataIndex: "division", key: "division", width: "14%" },
    { title: "Posisi", dataIndex: "position", key: "position", width: "16%" },
    {
      title: " Jabatan",
      dataIndex: "job_level",
      key: "job_level",
      width: "9%",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      width: "6%",
      render: (val, record) => (
        <div className="btn-group" role="group">
          <a
            onClick={() => navigate(`${routes_name.M_EMPLOYEE_SHOW}${val}`)}
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="add" />
          </a>

          <a
            onClick={() => navigate(`${routes_name.M_EMPLOYEE_DETAIL}${val}`)}
            style={{ marginRight: 5 }}
          >
            <MyIcon iconName="edit" />
          </a>
        </div>
      ),
    },
  ];
  const action = [
    <Link
      to={routes_name.M_EMPLOYEE_CREATE}
      className="btn icon icon-left btn-outline-primary"
    >
      <PlusOutlined /> {sys_labels.action.ADD}
    </Link>,
    // ,
  ];
  return (
    <AdminDashboard label={sys_labels.menus.MASTER}>
      <DataTablePagination
        fetchDataFunc={employee_providers.getData}
        columns={columns}
        title={sys_labels.menus.EMPLOYEE}
        action={action}
      />
    </AdminDashboard>
  );
};

export default Employee;
