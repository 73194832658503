import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as providers from "../../../providers/report/global";
import DataTablePagination, {
  DataTablePaginationReport,
} from "../../../components/DataTable";
import { sys_labels } from "../../../utils/constants";
import { SysCurrencyTransform, showToast } from "../../../utils/global_store";
import { routes_name } from "../../../route/static_route";
import TaxPdf from "../../PDF/tax_pdf";
import { pdf } from "@react-pdf/renderer";
import DataTablePaginationTaxReport from "../../../components/DataTable/DataTablePaginationTaxReport";

const ReportLeave = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Pegawai",
      dataIndex: "full_name",
      key: "full_name",
      sortable: true,
    },

    {
      title: "NIP",
      dataIndex: "employee_id",
      key: "employee_id",
      sortable: true,
    },
    {
      title: "Gaji",
      dataIndex: "payroll_final_salary",
      key: "payroll_final_salary",
      sortable: false,

      render: (val, record) => SysCurrencyTransform({ num: val, currency: "" }),
    },
    {
      title: "Terbayar",
      dataIndex: "payroll_dibayar",
      sortable: false,
      key: "payroll_tax",
      render: (val, record) => SysCurrencyTransform({ num: val, currency: "" }),
    },
    {
      title: "Terhutang",
      dataIndex: "payroll_terhutang",
      sortable: false,
      key: "payroll_taxes",
      render: (val, record) => SysCurrencyTransform({ num: 0, currency: "" }),
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "action",
      render: (val, record) => (
          <div className="btn-group" role="group">
            <a
              onClick={() => navigate(routes_name.REPORT_TAX_DETAIL+val,{state:record})}
              style={{ marginRight: 10 }}
              className="btn icon btn-primary btn-sm"
            >
              <i className="bi bi-file-text"></i>
            </a>
          </div>
        ),
    },
    
  ];
  return (
    <AdminDashboard label="">
      <DataTablePaginationTaxReport
        fetchDataFunc={providers.tax_reports}
        columns={columns}
        withPeriode={true}
        title={sys_labels.menus.TAX}
        filters={[]}
      />
    </AdminDashboard>
  );
};

export default ReportLeave;
