import { useEffect, useState } from "react";
import {
  SysDateTransform,
  SysJWTDecoder,
  SysMonthTransform,
  showToast,
} from "../../utils/global_store";
import AdminDashboard from "../AdminDashboard";
import LineChart from "./LineChart";
import * as providers from "../../providers/dashboard";
import * as providers_employee from "../../providers/master/employee";
import LoadingComponent from "../../components/LoadingComponent";
import EmptyComponent from "../../components/EmptyComponent";
import { sys_colors } from "../../utils/constants";
import MyIcon from "../../components/MyIcon";

function Dashboard() {
  const [total_employee, set_total_employee] = useState(0);
  const [total_absent, set_total_absent] = useState(0);
  const [loading_out, set_loading_out] = useState(true);
  const [loading_absent, set_loading_absent] = useState(true);
  const [loading_contratc, set_loading_contratc] = useState(true);
  const [loading_chart_late, set_loading_chart_late] = useState(true);
  const [loading_chart_payroll, set_loading_chart_payroll] = useState(true);
  const [data_out, set_data_out] = useState(null);
  const [data_absent, set_data_absent] = useState(null);
  const [data_contract, set_data_contract] = useState(null);
  const [data_chart_late, set_data_chart_late] = useState(null);
  const [data_chart_payroll, set_data_chart_payroll] = useState(null);

  const getOutEarlyEmployee = async () => {
    set_loading_out(true);
    try {
      const resp = await providers.getOutEarlyEmployee();
      let the_datas = resp.data;
      if (the_datas.data) {
        let data = [];
        the_datas.data.map((val) => {
          if (val.is_early.toLowerCase() == "yes") {
            data.push(val);
          }
        });
        the_datas.data = data;
      }
      set_data_out(the_datas);
    } catch (error) {
      showToast({ message: error.message });
    }
    set_loading_out(false);
  };

  const getTotalEmployee = async () => {
    try {
      const resp = await providers_employee.getData(1, 1);
      set_total_employee(resp.data.totalData);
    } catch (error) {
      console.error("Error fetching total employee:", error);
    }
  };

  const getAbsentEmployee = async () => {
    set_loading_absent(true);
    try {
      const resp = await providers.getAbsentEmployee();
      set_data_absent(resp.data);
      set_total_absent(resp.data.data.length);
    } catch (error) {
      showToast({ message: error.message });
    }
    set_loading_absent(false);
  };
  const getContractEmployee = async () => {
    set_loading_contratc(true);
    try {
      const resp = await providers.getContractStatus();
      set_data_contract(resp.data);
    } catch (error) {
      showToast({ message: error.message });
    }
    set_loading_contratc(false);
  };
  const getChartLate = async () => {
    set_loading_chart_late(true);
    try {
      const resp = await providers.getTotalLatePerMonth();
      let labels = [];
      let datas = [];
      let the_datas = resp.data;
      if (the_datas.series) {
        the_datas.series.map((val) => {
          let data = val.value;
          let label = val.date;
          label = label.substring(8, 10);
          if (label == "01") {
            label =
              SysMonthTransform(
                parseInt(val.date.substring(5, 7)),
                "long",
                "in"
              ) +
              " " +
              label;
          }
          labels.push(label);
          datas.push(data);
        });
      }
      set_data_chart_late({
        labels: labels,
        datasets: [
          {
            data: datas,
            backgroundColor: "#fff",
            borderColor: "#fff",
            tension: 0.5
          },
        ],
      });
    } catch (error) {
      showToast({ message: error.message });
    }
    set_loading_chart_late(false);
  };
  const getChartPayroll = async () => {
    set_loading_chart_payroll(true);
    try {
      const resp = await providers.getTotalPayroll();
      let labels = [];
      let datas = [];
      let the_datas = resp.data;
      if (the_datas.series) {
        the_datas.series.map((val) => {
          let data = val.value;
          let label = SysMonthTransform(
            parseInt(val.periode.substring(5, 7)),
            "long",
            "in"
          );
          labels.push(label);
          datas.push(data);
        });
      }
      set_data_chart_payroll({
        labels: labels,
        datasets: [
          {
            label: "Jumlah",
            data: datas,
            backgroundColor: "#fff",
            borderColor: "#fff",
            tension: 0.5
          },
        ],
      });
    } catch (error) {
      showToast({ message: error.message });
    }
    set_loading_chart_payroll(false);
  };

  useEffect(() => {
    getTotalEmployee();
    SysJWTDecoder();
    getAbsentEmployee();
    getChartLate();
    getChartPayroll();
    getContractEmployee();
    getOutEarlyEmployee();
    // console.log(window.location.protocol);
  }, []);
  return (
    <AdminDashboard label="Dashboard">
      <section className="section mt-0">
        <div className="row ">
          <div className="col-md-4" style={{ height: "350px" }}>
            <div
              className="card shadow"
              style={{
                backgroundColor: sys_colors.text.primary,
                height: "350px",
              }}
            >
              <div className="card-content">
                <div className="card-body">
                  <div className="d-flex justify-content-start align-items-center">
                    <MyIcon iconName="absent" size={50} />{" "}
                    <h5 className="card-title m-3 text-white">
                      Tidak Masuk Hari Ini
                    </h5>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="text-white">Total</p>{" "}
                    <p className="text-white">{total_absent}</p>
                  </div>
                  <div
                    class="progress"
                    style={{
                      borderRadius: 50,
                      backgroundColor: sys_colors.progress.secondary.inactive,
                    }}
                  >
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow={total_absent}
                      aria-valuemin="0"
                      style={{
                        width: `${(total_absent / total_employee) * 100}%`,
                        backgroundColor: sys_colors.progress.secondary.active,
                        borderRadius: 50,
                      }}
                      aria-valuemax={total_employee}
                    ></div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <p className="text-white">0%</p>{" "}
                    <p className="text-white">100%</p>
                  </div>
                  <div className="mt-2">
                    {loading_absent && <LoadingComponent />}
                    {!loading_absent && !data_absent?.data?.length > 0 && (
                      <EmptyComponent />
                    )}
                    {data_absent?.data?.length > 0 && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="mb-0 text-white">
                            {data_absent?.data[0].full_name}
                          </p>
                          <div className="d-flex align-items-center">
                            <p className="text-white">
                              {data_absent?.data[0].employee_id}
                            </p>
                          </div>
                        </div>
                        <p className="text-white">
                          {data_absent?.data[0].job_level_name}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ height: "350px" }}>
            <div
              className="card shadow"
              style={{
                backgroundColor: sys_colors.text.primary,
                height: "350px",
              }}
            >
              <div className="card-content">
                <div className="card-body">
                  <div className="d-flex justify-content-start align-items-center">
                    <MyIcon iconName="search" size={50} />{" "}
                    <h5 className="card-title m-3 text-white">
                      Terlambat hari ini
                    </h5>
                  </div>
                  {!loading_chart_late &&
                    !data_chart_late?.labels?.length > 0 && <EmptyComponent />}
                  {!loading_chart_late &&
                    data_chart_late?.labels?.length > 0 && (
                      <LineChart data={data_chart_late} />
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ height: "350px" }}>
            <div
              className="card shadow"
              style={{
                backgroundColor: sys_colors.text.primary,
                height: "350px",
              }}
            >
              <div className="card-content">
                <div className="card-body">
                  <div className="d-flex justify-content-start align-items-center">
                    <MyIcon iconName="payroll" size={50} />{" "}
                    <h5 className="card-title m-3 text-white">
                      Salary Spent
                    </h5>
                  </div>
                  {!loading_chart_payroll &&
                    !data_chart_payroll?.labels?.length > 0 && (
                      <EmptyComponent />
                    )}
                  {!loading_chart_payroll &&
                    data_chart_payroll?.labels?.length > 0 && (
                      <LineChart data={data_chart_payroll} />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminDashboard>
  );
}

export default Dashboard;
