import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard";
import * as providers from "../../../providers/transaction/leave";
import { DataTablePaginantionFilter } from "../../../components/DataTable";
import { sys_labels } from "../../../utils/constants";
import { routes_name } from "../../../route/static_route";
import { SysDateTransform } from "../../../utils/global_store";
import { PlusOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";

const Leave = () => {
  // SysDateTransform
  const columns = [
    {
      title: "Nomor Cuti",
      dataIndex: "id",
      key: "id",
      sortable: true,
      width: "20%",
      render: (val, record) => (
        <Link to={`/transaction/leave/show/${val}`}>{record.leave_no}</Link>
      ),
    },
    {
      title: "Pegawai",
      dataIndex: "employee",
      key: "employee",
      width: "23%",
      render: (val, record) => record.employee.name,
    },
    {
      title: "Tanggal",
      dataIndex: "leave_date",
      key: "leave_date",
      width: "20%",
      sortable: true,
      render: (val) =>
        SysDateTransform({
          date: val,
          checkIsToDay: true,
          withTime: false,
          type: "long",
          lang: "in",
        }),
    },
    {
      title: "Lama Cuti",
      dataIndex: "amount",
      key: "amount",
      width: "13%",
      render: (val, record) => val + " Hari",
      sortable: true,
    },
    {
      title: "Tipe",
      dataIndex: "leave_type",
      key: "leave_type",
      render: (val, record) => record.leave_type.name,
    },

    { title: "Alasan", dataIndex: "reason", key: "reason", sortable: true },
    // {
    //   title: "Approved By",
    //   dataIndex: "is_approved",
    //   key: "is_approved",
    //   sortable: true,
    //   render: (val, record) => record.is_approved,
    // },
    // { title: "Rejected By", dataIndex: "is_rejected", key: "is_rejected" },
  ];

  const action = [
    <Link
      to={routes_name.TRANSAC_LEAVE_CREATE}
      className="btn icon icon-left btn-primary btn-aditional"
    >
      <PlusOutlined /> {sys_labels.action.ADD}
    </Link>,
  ];

  return (
    <AdminDashboard label="">
      <DataTablePaginantionFilter
        fetchDataFunc={providers.getData}
        columns={columns}
        title={sys_labels.menus.LEAVE}
        action={action}
      />
    </AdminDashboard>
  );
};

export default Leave;
