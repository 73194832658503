import React, { useEffect } from "react";
import { renderToString } from "react-dom/server";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "./style.css";
import { sys_images } from "../utils/constants";
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType
);

const UploadFileCircle = ({ onImageUpload, file,disabled=false }) => {
  let renderer_file = file;
  const handleFileLoad = (file) => {
    if (file == null) {
      onImageUpload(null);
      return;
    }
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onImageUpload(base64String);
      };
      reader.readAsDataURL(file);
    } catch (error) {}
  };
  function genIcon() {
    return renderToString(
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img src={sys_images.img_upload} style={{width:"40%",height:"40%"}} />
      </div>
    );
  }
  return (
    <div>
      <FilePond
        allowFileTypeValidation={true}
        stylePanelLayout="circle"
        disabled ={disabled}
        labelIdle={genIcon()}
        acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
        files={renderer_file ? [renderer_file] : []}
        onupdatefiles={(fileItems) => {
          const selectedFile =
            fileItems && fileItems.length > 0 ? fileItems[0].file : null;
          handleFileLoad(selectedFile);
        }}
      />
    </div>
  );
};

export default UploadFileCircle;
